import React from "react"
import { graphql } from 'gatsby'
import { FormattedMessage } from 'react-intl'

import PageTemplate from '../components/pageTemplate'
import Crate from "../components/crate"
import MainContainer from "../components/mainContainer"
import ButtonAnimated from "../components/buttonAnimated"
import axios from "axios"
import qs from "qs"

class ContactPage extends React.Component {

  constructor(props, ...args) {
    super(props, ...args);

    this.state = {

      name: '',
      email: '',
      message: '',
      tncs: false,

      formValid: false,
      formErrors: {
        name: null,
        email: null,
        message: null,
        tncs: null,
      },

      msg_processing: false,
      msg_ok: false,
      msg_err: false,
    };
  }

  submitForm = (event) => {

    event.preventDefault()

    this.validateAllFields()
    if (!this.state.formValid) {
      return
    }

    this.setState({ msg_processing: true })

    const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
    const postData = qs.stringify({
      'name': this.state.name,
      'email': this.state.email,
      'message': this.state.message,
      'tncs': this.state.tncs,
    })

    axios.post('https://jogja2020.wpengine.com/wp-json/jja/v1/contact/', postData, config)
      .then(response => {
        this.setState({
          msg_ok: true,
          msg_processing: false,
        })
      })
      .catch(error => {
        console.log(error.response.data.message)
        this.setState({
          msg_err: true,
          msg_processing: false,
        })
      })
  }

  setValue = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState({ [name]: value }, () => {
      this.validateSingleField(name)
    });
  }

  setTncs = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.checked;

    this.setState({ [name]: value }, () => {
      this.validateSingleField(name)
    });
  }

  render() {

    const { data } = this.props
    const locale = this.props.pageContext.locale

    const title = "Contact Us"
    const content = data.wordpressPage.content

    const msg_ok = this.state.msg_ok
    const msg_err = this.state.msg_err
    const msg_processing = this.state.msg_processing

    return (

      <PageTemplate locale={locale} title={title} icon={"icon-a"} headerImage={this.props.data.headerImage}>
        <Crate>
          <MainContainer className="px-5 sm:px-0 py-3 text-gray-900">
            <div className="flex flex-wrap -mx-5 py-10">
              <div className="w-full md:w-1/2 lg:w-4/12 px-5 my-3"
                dangerouslySetInnerHTML={{ __html: content }}>
              </div>
              <div className="w-full md:w-1/2 lg:w-8/12 px-5">
                <div className="w-full bg-blue-100 p-5 rounded-lg my-3">

                  {msg_ok &&
                    <p><FormattedMessage id="Thank you, your message has been sent" /></p>
                  }
                  {!msg_ok &&
                    <div>
                      {msg_err &&
                        <p className="bg-pink-900 text-white px-3 py-3 rounded">
                          <FormattedMessage id="Oops, there was an issue submitting this..." />
                          <a className="underline" href="mailto:jesedujogja@educatemagis.org">jesedujogja@educatemagis.org</a>
                        </p>
                      }
                      <form onSubmit={this.submitForm} >
                        <div className="mb-4">
                          {(this.state.formErrors.name === false) &&
                            <p className="my-2 text-osm" style={{ color: '#E6656A' }}>
                              <FormattedMessage id="Required Field:" />
                              <FormattedMessage id="Name" />
                            </p>
                          }
                          <FormattedMessage id="Name">
                            {placeholder =>
                              <input
                                value={this.state.name}
                                className="bg-white appearance-none rounded-lg w-full py-2 px-3 text-purple-900 leading-tight focus:outline-none focus:shadow-outline"
                                name="name"
                                onChange={this.setValue}
                                type="text"
                                placeholder={`${placeholder}*`} />
                            }
                          </FormattedMessage>
                        </div>
                        <div className="mb-4">
                          {(this.state.formErrors.email === false) &&
                            <p className="my-2 text-osm" style={{ color: '#E6656A' }}>
                              <FormattedMessage id="Required Field:" />
                              <FormattedMessage id="Email" />
                            </p>
                          }
                          <FormattedMessage id="Email">
                            {placeholder =>
                              <input
                                value={this.state.email}
                                className="bg-white appearance-none rounded-lg w-full py-2 px-3 text-purple-900 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                name="email"
                                onChange={this.setValue}
                                type="email"
                                placeholder={`${placeholder}*`} />
                            }
                          </FormattedMessage>
                        </div>
                        <div className="mb-4">
                          {(this.state.formErrors.message === false) &&
                            <p className="my-2 text-osm" style={{ color: '#E6656A' }}>
                              <FormattedMessage id="* Required Field:" />
                              <FormattedMessage id="Message" />
                            </p>
                          }
                          <FormattedMessage id="Message">
                            {placeholder =>
                              <textarea
                                className="bg-white h-32 appearance-none rounded-lg w-full py-2 px-3 text-purple-900 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                name="message"
                                onChange={this.setValue}
                                type="password"
                                placeholder={`${placeholder}*`}>
                              </textarea>
                            }
                          </FormattedMessage>
                        </div>
                        <label className="block text-gray-500 my-4 text-oxs">
                          {(this.state.formErrors.tncs === false) &&
                            <p className="my-2 text-osm" style={{ color: '#E6656A' }}>
                              <FormattedMessage id="* Required Field:" />
                              <FormattedMessage id="Terms Conditions" />
                            </p>
                          }
                          <input
                            name="tncs"
                            type="checkbox"
                            checked={this.state.tncs}
                            onChange={this.setTncs}
                            className="mr-2 leading-tight" />
                          <FormattedMessage id="By checking this box, you agree that JES..." />
                        </label>
                        <div className="flex justify-end">
                          <ButtonAnimated loading={msg_processing}></ButtonAnimated>
                        </div>
                      </form>
                    </div>
                  }
                </div>
              </div>
            </div>
          </MainContainer>
        </Crate>
      </PageTemplate>
    )
  }

  validateForm() {

    let formValid = true
    for (var key in this.state.formErrors) {
      if ((this.state.formErrors.hasOwnProperty(key)) && (this.state.formErrors[key] !== true)) {
        formValid = false
        break;
      }
    }
    this.setState({ formValid: formValid });
  }

  validateAllFields() {

    let formErrors = {}
    let fields = [
      'name',
      'email',
      'message',
      'tncs'
    ]

    for (var i = 0; i < fields.length; i++) {
      formErrors[fields[i]] = this.fieldIsValid(fields[i])
    }

    this.setState({ formErrors: formErrors }, this.validateForm);
  }

  validateSingleField(fieldName) {

    let formErrorsCopy = Object.assign({}, this.state.formErrors)
    formErrorsCopy[fieldName] = this.fieldIsValid(fieldName)
    this.setState({ formErrors: formErrorsCopy }, this.validateForm);
  }

  fieldIsValid(fieldName) {

    switch (fieldName) {
      case 'name':
        return ((/^ *$/.test(this.state['name'])) === false)
      case 'email':
        return ((/^ *$/.test(this.state['email'])) === false)
      case 'message':
        return ((/^ *$/.test(this.state['message'])) === false)
      case 'tncs':
        return (this.state['tncs'] === true)
      default:
        return false
    }
  }
}

export default ContactPage

export const query = graphql`
  query($pId: Int!, $pType: String!) {
      wordpressPage(wordpress_id: {eq: $pId}, type: {eq: $pType}) {
      title
        content
    }
    headerImage: file(relativePath: { eq: "jesedu2020-plain-dark.png" }) {
      childImageSharp {
        fixed(width: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }`
